<template>
    <div class="cs-block cs-showcases-circles-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base" :class="rootClasses">
            <div class="container">
                <h2 class="cs-title" v-if="data.title">
                    <ColorSplit :data="data.title" />
                </h2> 
                <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
                <span class="cs-text" v-if="data.text" v-html="data.text"></span>

                <div class="image-container">
                    <div class="image" v-for="image in data.images">
                        <a :href="image.url"><img :src="image.image"></a>
                    </div>
                </div>

                <div v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))" class="block-buttons">
                    <Buttons :data="data.buttons" :cs_style="data.style" />    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

    export default {
        name: 'ShowcaseCircles',
        props: {
            data: {
                type: Object,
            default: () => {}
            }
        },
        components: {
            ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
            Buttons: () => import("../components/CSUtilsButtons.vue"),
        },
        computed: {
            ...computed('ShowcaseCircles'),
            hasBg() {
                if (this.data.style == 'color') return ' has-background';

                return '';
            }
        },
        methods: {}
    } 
</script>

<style lang="scss" scoped>
    @import '../styles/main.scss';
    .cs-showcases-circles-block{

        .image-container {
            column-count: 4;
            column-gap: 10px;
            margin: 30px 0;
            .image {
                margin: 0;
                display: grid;
                grid-template-rows: 1fr auto;
                margin-bottom: 10px;
                break-inside: avoid;
                a {
                    text-decoration: none;
                    grid-row: 1 / -1;
                    grid-column: 1;
                    img {
                        width: 100%;
                        border-radius: 30rem;
                        border: 2px solid #1ec784;
                    }
                }

            }
        }

        .cs-align-center *{
            text-align: center;
        }
        .cs-align-left *{
            text-align: left;
        }
        .cs-align-right *{
            text-align: right;
        }
        &.cs-style- {
            &color {
                @include cs-block-color;
                .cs-sub-title{
                    color:$sub_title_color;
                }
                *, ::v-deep *{
                    color:white;
                }
            }

            &light {
                @include cs-block-light;
                .cs-sub-title{
                    color: $sub_title_color_light;
                }
            }

            &dark {
                @include cs-block-dark;
                .cs-sub-title{
                    color: $sub_title_color_dark;
                }
            }
        }
        .cs-sub-title{
            font-weight: 400;
            padding: 10px 0;
            font-size: 20px;
        }

    }
</style>
